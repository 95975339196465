import { call, unregisterHandler } from '@/bridge';
import { useEffect } from 'react';

const fnArray: any[] = [];

// 中断tapback
export class InterruptBackError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InterruptBackError';
  }
}
// 继续执行后续行为
export class ContinueBackError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ContinueBackError';
  }
}

function executePromisesSerially(func: Function[]) {
  return func.reduce((pre, cur) => {
    return pre.then(async () => {
      try {
        const res = await cur();
        return res;
      } catch (error) {
        if (error instanceof ContinueBackError) {
          // 正常异常 继续执行后置回调
          console.log('ContinueBackError');

          return;
        }
        throw error;
      }
    });
  }, Promise.resolve());
}

export function useDidTapBack(fn: (...args: any[]) => any) {
  const alreadyExist = fnArray.some((fnItem) => {
    // 对于使用memo或者在组件函数外声明的函数，以及写匿名函数的情况
    return fnItem == fn || fnItem.toString() == fn.toString();
  });
  // 相同的函数已经存在，则不再推进数组
  if (!alreadyExist) {
    fnArray.push(fn);
  }

  useEffect(() => {
    const didTapBack = call('didTapBack', async function () {
      try {
        // 串行调用
        await executePromisesSerially(fnArray);
      } catch (error) {
        if (error instanceof InterruptBackError) {
          // 正常异常 中断
          console.log('InterruptBackError');
          return;
        }
        console.warn('useDidTapBack', { error });
      }
      console.warn('didTapBack');
      call('popWebView');
    });
    return () => {
      unregisterHandler(didTapBack, 'didTapBack');
    };
  }, []);
}
