import { DiscountVoucher } from '@/components/Voucher/common/types';
import { formatMsg } from '@/locales';

export type IResponse<T> = {
  request_id: string;
  code: number;
  message: string;
  data: T;
};

export const CERTI_TYPE_MAP = {
  80: 'NRIC',
  81: 'OIC',
  2: 'PASSPORT',
  82: 'Army/Polic',
} as const;

export type CERTI_ENUM = keyof typeof CERTI_TYPE_MAP;

export const GENDER_MAP = {
  0: 'unknown',
  1: 'male',
  2: 'female',
} as const;

export type GENDER_ENUM = keyof typeof GENDER_MAP;

export const MARITAL_STATUS_MAP = {
  1: 'Single',
  2: 'Married',
  3: 'Widow',
  4: 'Divorce',
} as const;

export type MARTIAL_STATUS_ENUM = keyof typeof MARITAL_STATUS_MAP;

export const RECONDITION_MAP = {
  yes: 'recondition',
  no: 'not recondition',
} as const;

export type RECONDITION_ENUM = keyof typeof RECONDITION_MAP;

export enum EProductMainType {
  all = 0,
  product_protection = 1,
  health = 2,
  accident = 3,
  life = 4,
  product_liability = 5,
  home_content = 6,
  motor = 7,
  travel = 10,
}

export enum EProductCategory {
  unspecified = 0,
  ec = 1,
}

export enum EPolicyCategory {
  unspecified = 0,
  ec = 2,
}

export const ContactTypeMap = {
  1: 'phone_number',
  2: 'whatsapp',
  3: 'email',
  4: 'web',
  5: 'zalo',
  6: 'line',
} as const;
export type ContactType = (typeof ContactTypeMap)[keyof typeof ContactTypeMap];

export type IVehicleOwner = {
  zip_code?: string;
  certi_type?: CERTI_ENUM;
  certi_no?: string;
  // format '1991-11-11'
  birthday?: string;
  gender?: GENDER_ENUM;
  marital_status?: MARTIAL_STATUS_ENUM;
  full_name?: string;
  phone_no?: string;
  phone_country_code?: string;
  local_phone_no?: string;
  email?: string;
  address?: string;
  state?: string;
};

export type IVehicleInfo = {
  vehicle_no: string;
  is_recondition?: RECONDITION_ENUM;
  make_code?: string;
  make?: string;
  model_code?: string;
  model?: string;
  year_of_manufacture?: string;
  engine?: string;
  engine_cc?: number;
  seating_capacity?: number;
  no_of_claims?: number;
  chassis?: string;
  av_make_code?: string;
};

export type IVehiclePolicy = {
  pol_effective_date: string;
  pol_expiry_date: string;
  NcdPercentage: string;
};

export type IVehicleExt = {
  contract_number: string;
  // 无索赔折扣
  ncd_percentage: number;
  hrtv_ind: boolean;
  high_performance_ind: boolean;
  excess_waive_ind: boolean;
};

export enum EMotorCyclePlanCoverageType {
  'BasicCoverage' = 'BasicCoverage',
  'ComplimentaryCoverage' = 'ComplimentaryCoverage',
}

export interface PlanCoverage {
  coverage: string;
  sum_insured: string;
  coverage_extra_desc: string;
  sum_assured: string;
  coverage_id: string;
  coverage_name: string;
  coverage_desc: string;
  coverage_opt_type: number;
  tag: string;
  iconType?: string;
  link?: string;
  customClass?: string;
  additional_driver_list: any[];
  coverage_type?: EMotorCyclePlanCoverageType;
}

export interface BasicLayer {
  layer_id: string;
  layer_code: string;
  layer_name: string;
  layer_desc: string;
  layer_extra_desc: string;
  layer_sub_extra_desc: string;
  exclusion_id: number;
  layer_extra: LayerExtra;
  plan_coverages: PlanCoverage[];
}

export interface LayerExtraOption {
  name: string;
  value: string;
  etx: string;
}

export interface LayerExtra {
  layer_extra_option_config: string;
  layer_extra_option_title: string;
  layer_extra_type: string;
  layer_extra_options: LayerExtraOption[];
}

export interface AdditionalLayer {
  layer_id: string;
  layer_code: string;
  layer_name: string;
  layer_desc: string;
  layer_extra_desc: string;
  layer_sub_extra_desc: string;
  exclusion_id: number;
  layer_extra: LayerExtra;
  layer_seq: number; // 序号
  plan_coverages: PlanCoverage[];
  // 前端内部字段 处于处理词条
  // UNKNOWN 展示选项
  extra_entry_info?: 'SALOON' | 'NON-SALOON' | 'UNKNOWN' | 'INFO_MISMATCH';
  saloonInfo?: 'SALOON' | 'NON-SALOON' | 'UNKNOWN' | 'INFO_MISMATCH';
  is_disable: boolean; // 是否前端可选标记位，true：不可选， false：可选
  layer_mark?: string;
  premium?: string;
  voucher_infos?: DiscountVoucher[];
  layer_type: number;
}

export interface PlanPeriod {
  period_unit: number;
  period_unit_type: string;
  period_unit_text: string;
  basic_layers: BasicLayer[];
  basic_layer: BasicLayer;
  layers: AdditionalLayer[];
  additional_layers: AdditionalLayer[];
  // 2.2.34新增
  activity_infos?: IActivityInfoV2[];
  plan_coverages?: PlanCoverage[];
}
export interface ProductPlan {
  plan_id: string;
  product_id?: string;
  plan_code: string;
  plan_name: string;
  plan_desc: string;
  activity_info?: IActivityInfo;
  activity_infos?: IActivityInfo[];
  plan_periods: PlanPeriod[];
  period_unit?: string;
  period_unit_type?: string;
  period_unit_text?: string; // 时间单位国际化字段
  // 保单详情
  plan_coverages?: {
    coverage?: string;
    sum_assured?: string;
    coverage_extra_desc?: string;
  }[];

  plan_layers: BasicLayer[];
  plan_classification: string;
}

export interface ProductPlanV2 {
  plan_id: string;
  plan_code: string;
  plan_name: string;
  plan_desc: string;
  plan_periods: PlanPeriod[];
}

export type IActivityInfo = Partial<{
  activity_id: string;
  activity_product_id: string;
  discount_type: number;
  total_discount: string;
  display_discount: string; // 总折扣 fe_total_discount+fe_cashback=display_discount
  fe_total_discount: string; // 当前黄色ribbon展示字段
  fe_cashback: string; // 当前cashback展示字段
  activity_type: 'Direct_Discount' | 'Cashback'; // 活动类型
  fe_cashback_amount: string;
  fe_discount_amount: string;
}>;

export enum EDiscountType {
  percentage = 1,
  currency = 2,
}

export type TActivityType = 'Direct_Discount' | 'Cashback' | 'Coinback'; // 活动类型

/**
 * v2.2.34 MY promotion新增
 *
 */
export type IActivityInfoV2 = Partial<{
  activity_id: string;
  activity_product_id: string;
  discount_type: EDiscountType; // 折扣数据类型
  activity_type: TActivityType; // 活动类型
  total_discount: string; // 折扣
  fe_total_discount: string; // 折扣，当activity_type = Direct_Discount 返回，前端需根据discount_type判断展示的是百分比还是直接折扣数值
  fe_cashback: string; // 假返现，当activity_type = Direct_Discount 返回，1.只有id motor场景下会返回 2.前端需根据discount_type判断展示的是百分比还是直接折扣数值
  fe_actual_cashback: string; // 真返现，当activity_type = Cashback 返回 PS：前端需根据discount_type判断展示的是百分比还是直接折扣数值
  total_discount_amount: string;
}>;

/**
 * v5.2.43 TH/PH promotion
 */
export type IActivityInfoV3 = Partial<{
  activity_id: string;
  activity_product_id: string;
  discount_type: EDiscountType;
  total_discount: string;
  activity_type: 'Direct_Discount' | 'Cashback' | 'Coinback'; // 活动类型
  has_fake_cashback: boolean;
  fake_total_discount: string;
  fake_cashback: string;
  total_discount_amount: string; // 具体金额
  is_flash_sale?: boolean;
}>;

export interface SelectedPlan {
  plan_id?: string;
  plan_name?: string;
  plan_desc?: string;
  plan_code?: string;
  period_unit?: string;
  period_unit_type?: string;
  // 用于单位国际化显示
  period_unit_text?: string;
  plan_coverages?: {
    coverage?: string;
    sum_assured?: string;
    coverage_extra_desc?: string;
  }[];
  plan_total_price?: string;
}

export enum ProductSubType {
  car = 'car',
  motorcycle = 'motorcycle',
  extended_warranty = 'extended_warranty',
  product_liability = 'product_liability',
}

export interface Product {
  decision_questions: {
    question_id: string;
    question_code: string;
    question_desc: string;
    answer: {
      code: string;
      value: string;
    }[];
  }[];
  product_id: string;
  product_main_type?: ProductMainType | string;
  product_sub_type?: ProductSubType;
  product_name: string;
  top_banner_image_url?: string;
  left_big_text?: string;
  left_small_text?: string;
  right_big_text?: string;
  right_small_text?: string;
  product_summary_link?: string;
  see_more_show?: number; // 1 = true, 0 = false
  collapse_image_url?: string;
  expand_image_url?: string;
  claim_process_image_url?: string;
  claim_process_redirection_link?: string;
  claim_process_timeline_image_url?: string;
  frequently_asked_questions?: {
    question: string;
    answer: string;
  }[];
  customer_service_info?: {
    partner_logo_image_url: string;
    partner_display_name: string;
    partner_first_address: string;
    partner_second_address: string;
    partner_third_address: string;
    contact_infos: {
      contact_type: ContactType;
      contact_content: string;
    }[];
    operation_hours: string;
    extra: {
      partner_display_name: string;
      operation_hours: string;
      contact_infos: {
        contact_type: ContactType;
        contact_content: string;
      }[];
    };
  };
  customer_service_infos?: Product['customer_service_info'][];
  road_tax_customer_service?: any;
  product_cover_image_url?: string;
  premium_message?: string;
  partner_text?: string;
  product_tag?: string;
  key_selling_points?: string[];
  // 1.2.8
  product_plans?: ProductPlan[];
  // 1.2.9版本新增
  product_plan_v2s?: ProductPlan[];
  terms_condition_link?: string;
  // 用户是否在白名单决定用户是否能购买产品
  is_user_whitelist?: boolean;
  checkout_tc: string;
  tc_json_str: string;
  // 活动信息
  activity_info?: IActivityInfo;
  how_to_claim: string;
  premium_before_discount: string;
  premium_after_discount: string;
  // 2.2.34新增
  show_activity_infos?: IActivityInfoV2[]; // pdp,quote
  activity_infos: IActivityInfoV2[]; // recommended card
  inside_product_tag?: {
    tag_type: string;
    tag_content: string[];
  }[];
  recommended_add_ons?: { name: string }[];
}

export interface ProductShelvesItem {
  product_id: string;
  product_display_name: string;
  product_main_type: ProductMainType;
  product_cover_image_url: string;
  key_selling_points: string[];
  premium_display: PremiumDisplay;
  product_tags: ProductTag[];
}

export interface ProductTag {
  tag_type:
    | 'discount'
    | 'cashback'
    | 'period'
    | 'installment'
    | 'mark'
    | 'coinback'
    | 'voucher'
    | 'flash_sale_discount';
  tag_content_list: { item?: string[] }[];
}

export interface PremiumDisplay {
  show_premium_type: 'premium' | 'premium_message';
  premium_message: string;
  premium_before_discount: string;
  premium_after_discount: string;
}

export enum EPageSource {
  standalone_motor_pdp = 11,
  standalone_motor_quote_detail = 12,
  standalone_th_motor_checkout = 13,
  standalone_th_motor_payment_result = 14,
  standalone_homepage = 18,
  standalone_voucher_list = 19,
  standalone_voucher_redemption = 20,
  // 车险和意健险page source code不一致，所以统一维护字符串
  standalone_checkout = 'standalone_checkout',
  standalone_pdp = 'standalone_pdp',
  standalone_quote_detail = 'standalone_quote_detail',
  standalone_quote_list = 'standalone_quote_list',
  standalone_renew_policy_detail = 'standalone_renew_policy_detail',
  standalone_payment_result = 'standalone_payment_result',
  standalone_policy_list = 'standalone_policy_list',
  standalone_policy_detail = 'standalone_policy_detail',
  ah_product_detail_page = 'ah_product_detail_page',
}

export enum ERelationShip {
  Myself = 1,
  Spouse = 2,
  Parent = 3,
  Child = 4,
  OthersFamilyMember = 5,
}

export const RelationShip = {
  myself: 'myself',
  spouse: 'spouse',
  parent: 'parent',
  child: 'child',
  others_family_member: 'others_family_member',
} as const;

export type RelationShipKey = keyof typeof RelationShip;

export enum EPurchaseFlowSource {
  unspecified = 'unspecified',
  buy_new = 'buy_new',
  renew = 'renew',
  buy_again = 'buy_again', // vn之后已经废弃buy_again流程
  pay_new = 'pay_new', // 意健险、非重构后车险 checkoout pay now btn
  get_quote = 'get_quote', // PDP 获取报价按钮
  buy_now = 'buy_now', // product page detail点击 buy now 按钮 /quote 页面点击next按钮
  confirm_and_checkout = 'confirm_and_checkout', // 点击confirm and checkout 按钮
  pay_now = 'pay_now', // checkout 页面点击pay now 按钮
  pay_continue = 'pay_continue', // policy list/detail page 点击 pay now 按钮
}

export const ProductMainTypeMap = {
  1: 'product_protection',
  2: 'health',
  3: 'accident',
  4: 'life',
  5: 'product_liability',
  6: 'home_content',
  7: 'motor',
  10: 'travel',
} as const;

export type ProductMainType = (typeof ProductMainTypeMap)[keyof typeof ProductMainTypeMap];

export interface InsureParam {
  product_id?: string;
  plan_id: string;
  period_unit: string | number;
  period_unit_type: string | number;
  period_unit_text: string | number;
  add_layer_id_list?: string[];
  sum_insured?: string;
}
export enum EGender {
  Unknown = '',
  Female = 'female',
  Male = 'male',
}

export enum EResourceCode {
  VoucherMineBanner = 'voucher_list:product_card',
  VoucherRedeemBanner = 'voucher_redemption:product_card',
  PaymentResultBanner = 'payment_result:product_card',
  HomeVoucher = 'standalone_homepage:voucher',
  FreePDPProductCard = 'free_pdp:product_card',
  FreePDPUpgradeButton = 'free_product:upgrade_button',
  PolicyDetailProductCard = 'policy_detail:product_card',
  PaymentResultRecommend = 'payment_result:recommend',
  PaymentResultVoucher = 'payment_result:voucher',
  PolicyListRecommend = 'policy_list:recommend',
  PolicyListProductCard = 'policy_list:product_card',
  PolicyListVoucher = 'policy_list:voucher',
  HomeRecommend = 'homepage:recommend',
  HomePopUp = 'homepage:pop_up',
  HomeBanner = 'homepage:banner',
  EducationRecommend = 'education:recommend',
  MotorPopup = 'motor_pdp:pop_up',
  PdpNoticeBar = 'product_detail:notice_bar',
  ReferralCard = 'homepage:referral',
  PaymentReferralCard = 'payment_result:referral',
  ReferralPageCard = 'referral_landing_page:referral',
}

export enum ERecommendInfoType {
  RecommendedProducts = 'carousel:recommend', // 推荐卡片
  ProtectionProducts = 'carousel:protection',
  ProductCards = 'carousel:product_card', // 轮播banner
  VoucherCard = 'carousel:voucher', // 轮播voucher
  PopUpCard = 'pop_up:product_card',
  NoticeBar = 'notice_bar:message',
}

export enum EReferIdType {
  ProductId = 1,
  PolicyId = 2,
  OrderId = 3,
}

export interface ResourceConfigDetail {
  pic_link: string;
  redirect_link: string;
  id?: string;
  item_id?: string;
  title?: string;
  pop_up_times?: number;
  width_ratio?: number;
  carousel_time?: number;
  text?: string;
}

export type ReferralCardInfo = {
  pic_link: string;
  redirect_link: string;
  referral_code: string;
  first_icon_content: string;
  second_icon_content: string;
  third_icon_content: string;
  footnote_wording: string;
  above_pic_link: string;
  below_pic_link: string;
};

export enum VoucherState {
  unclaimed = 1, // 待领取
  unused = 2, // 待使用
}
export interface VoucherDetail {
  voucher_title: string;
  voucher_description: string;
  voucher_label: string;
  voucher_id: number | string;
  voucher_state: (typeof VoucherState)[keyof typeof VoucherState];
  voucher_claim_url: string;
  voucher_use_url?: string;
}

export type KycFlowType = 'no_kyc_flow' | 'full_kyc_flow' | 'simplified_kyc_flow';

export const ETypeOfUsage: Record<string, any> = {
  personal: 'Individual',
  office: 'Company',
};

export enum PremiumDetailType {
  STAMP_TAX = 'stamp_tax',
  ADMIN_FEE = 'admin_fee',
}

export interface IPremiumDetails {
  premium_type: string;
  premium: string;
  desc: string;
}

export enum EBroker {
  Allianz = 'Allianz',
  Zurich = 'Zurich',
  Etiqa = 'Etiqa',
}

export type IDispatchVoucherDataResponse = IResponse<{
  biz_content: {
    biz_type: number;
    biz_result: string;
  }[];
}>;

export type IDiscountInfo = {
  group_discount_amount: string;
  discount_amount: string;
  cashback_amount: string;
  coin_back_amount: string;
  activity_detail: IDiscountInfo;
  voucher_detail: IDiscountInfo;
};

export enum VoucherErrorCode {
  VoucherExpired = 10159108,
  VoucherFullRedeemed = 10159109,
  VoucherInvalid = 10176013,
}

class VoucherErrorTitle {
  get [VoucherErrorCode.VoucherExpired]() {
    return formatMsg('voucher.error.title.expired');
  }
  get [VoucherErrorCode.VoucherFullRedeemed]() {
    return formatMsg('voucher.error.title.fullRedeemed');
  }
  get [VoucherErrorCode.VoucherInvalid]() {
    return formatMsg('voucher.error.title.invalid');
  }
}

export const voucherErrorTitle = new VoucherErrorTitle();

class VoucherErrorContent {
  get [VoucherErrorCode.VoucherExpired]() {
    return formatMsg('voucher.error.content.expired');
  }
  get [VoucherErrorCode.VoucherFullRedeemed]() {
    return formatMsg('voucher.error.content.fullRedeemed');
  }
  get [VoucherErrorCode.VoucherInvalid]() {
    return formatMsg('voucher.error.content.invalid');
  }
}

export const voucherErrorContent = new VoucherErrorContent();

export enum PREMIUM_CALC_STATUS {
  LOADING = 'loading',
}

export enum ERenewStatus {
  renewed = 4,
}

export enum DevicePlatformType {
  UnKnown,
  iOSWeb,
  iOSApp,
  AndroidWeb,
  AndroidApp,
  PC,
  Others = 128,
}

export enum AppEnvType {
  Unknown = 0,
  Shopee = '1',
  ShopeePay = '5',
}

export enum EIdType {
  NRIC = 'nric',
  OIC = 'oic',
  Passport = 'passport',
  ArmyPolice = 'army_police',
}

export enum EMarital {
  Unknown = '',
  Single = 'single',
  Married = 'married',
  Widow = 'widowed',
  Divorce = 'divorced',
}

export enum ERecondition {
  Recondition = 'yes',
  Not = 'no',
}
export interface AccountMember {
  account_member_id: string;
  real_name: string;
  relationship: RelationShipKey;
  certi_no: string;
  certi_type: string;
}

export interface ClassifyMember {
  relationship: RelationShipKey;
  account_member_info_list: AccountMember[];
}

export interface InsuredInfo {
  relationship: ERelationShip;
  name: string;
  certi_no?: string;
  birthday?: string;
}

export enum EMYProductID {
  PersonalAccident = '1873595514786987560',
  UnsellPersonalAccident = '1918987353177612838',
}
export type IInstallRateType = 'free_rate' | 'prom_rate' | 'normal';
export type IInstallment = {
  rate_type: IInstallRateType;
  premium_text: string;
  // 分期数
  // term: Number;
  // 每一期钱
  // term_amount: String;
  // 红框label
  title_text?: string;
  // 右边label
  sub_title_text?: string;
};

export type IPremiumInstallment = {
  installment: IInstallment;
  bundle_installment?: IInstallment;
};

export enum ShareConfigEnm {
  WHATSAPP = 'whatsapp',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebookLink',
  TELEGREM = 'telegram',
  COPYLINK = 'copyLink',
  COPYINFO = 'copyInfo',
  EMAIL = 'email',
  SMS = 'sms',
  MORE = '__more',
}

// referrall code校验异常码
export const ReferralErrorCode = [
  157811100, 157811101, 191813006, 191813010, 157811102, 191813004, 191813009, 157811103,
];
export type VolumeInfo = {
  volume_results: {
    change_payment_method_rule: boolean;
  };
};
