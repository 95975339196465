import IDCfg from './id';
import MYCfg from './my';
import VNCfg from './vn';
import PHCfg from './ph';
import THCfg from './th';
import SGCfg from './sg';
import BRCfg from './br';

export type CfgType = typeof IDCfg;

const configMap = {
  id: IDCfg,
  my: MYCfg,
  vn: VNCfg,
  ph: PHCfg,
  th: THCfg,
  sg: SGCfg,
  br: BRCfg,
};

const config = configMap[process.env.REGION as keyof typeof configMap] as {
  secretKey: string;
  appId: string;
  uploadHost: string;
  livenessUplaodHost: string;
  helpCenterLink: string;
};

export default config;
